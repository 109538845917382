import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import cn from "classnames"

import "./header.scss"

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap"

const Header = ({ siteTitle, logo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const [isVisible, setIsVisible] = useState(true)

  const handleScroll = (event) => {
    if (scrollY <= event.currentTarget.scrollY) {
      setScrollY(event.currentTarget.scrollY)
      setIsVisible(false)
    }

    if (scrollY > event.currentTarget.scrollY) {
      setIsVisible(true)
      setScrollY(event.currentTarget.scrollY)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Navbar
      fixed={"top"}
      dark
      expand="md"
      className={cn("navbar sticky", { 'is-hidden': !isVisible })}
    >
      <Container>
        <Link to="/#hero" className="navbar-brand">
          <img
            src={logo}
            alt={`${siteTitle} Logo`}
            className="navbar-logo"
            />
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="/#hero"
                >
                  Home
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/#feature">
                  Projekte
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="/kunst"
                >
                  Kunst
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="/about/#about"
                >
                  Über uns
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="/contact/#contact"
                >
                  Kontakt
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
  )
}

export default Header
